<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/center-institution' }">
        <app-i18n code="entities.centerInstitution.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n
          code="entities.centerInstitution.view.title"
        ></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n
          code="entities.centerInstitution.view.title"
        ></app-i18n>
      </h1>

      <div
        class="app-page-spinner"
        v-if="loading"
        v-loading="loading"
      ></div>

      <app-center-institution-view-toolbar
        v-if="record"
      ></app-center-institution-view-toolbar>

      <el-form
        :label-position="labelPosition"
        :label-width="labelWidthForm"
        @submit.prevent.native
        class="form"
        v-if="record"
      >
        <app-view-item-text
          :label="fields.name.label"
          :value="presenter(record, 'name')"
        ></app-view-item-text>

        <app-view-item-relation-to-one
          :label="fields.faculty.label"
          :readPermission="fields.faculty.readPermission"
          :navigatePermission="fields.faculty.navigatePermission"
          :url="fields.faculty.viewUrl"
          :value="presenter(record, 'faculty')"
        ></app-view-item-relation-to-one>

        <app-view-item-relation-to-one
          :label="fields.location.label"
          :readPermission="fields.location.readPermission"
          :navigatePermission="fields.location.navigatePermission"
          :url="fields.location.viewUrl"
          :value="presenter(record, 'location')"
        ></app-view-item-relation-to-one>

        <app-view-item-custom
          :label="fields.disabledAsStatus.label"
          :value="presenter(record, 'disabledAsStatus')"
        >
          <el-tag
            :type="
              record[fields.disabledAsStatus.name]
                ? 'danger'
                : 'success'
            "
            >{{ presenter(record, 'disabledAsStatus') }}</el-tag
          >
        </app-view-item-custom>

        <app-view-item-text
          :label="fields.createdAt.label"
          :value="presenter(record, 'createdAt')"
        ></app-view-item-text>

        <app-view-item-text
          :label="fields.updatedAt.label"
          :value="presenter(record, 'updatedAt')"
        ></app-view-item-text>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CenterInstitutionViewToolbar from '@/modules/center-institution/components/center-institution-view-toolbar.vue';
import { CenterInstitutionModel } from '@/modules/center-institution/center-institution-model';

const { fields } = CenterInstitutionModel;

export default {
  name: 'app-center-institution-view-page',

  props: ['id'],

  components: {
    [CenterInstitutionViewToolbar.name]: CenterInstitutionViewToolbar,
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'centerInstitution/view/record',
      loading: 'centerInstitution/view/loading',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.id);
  },

  methods: {
    ...mapActions({
      doFind: 'centerInstitution/view/doFind',
    }),

    presenter(record, fieldName) {
      return CenterInstitutionModel.presenter(record, fieldName);
    },
  },
};
</script>
